import db from '@/utils/localStorage'
export const namespaced = true;

export const types = {
  SHOW_MSG_DLG: 'SHOW_MSG_DLG',
  SHOW_GLOBAL_LOADING: 'SHOW_GLOBAL_LOADING',
};

export const state = {
  showFloatIcon: true,
  show: false,
  globalLoading: {
    duration: 0,
    message: '',
    loadingType: 'spinner',
    show: false,
  },
  theme: 'light',
  keepAlive: ['Home'],
  backUrl: '',
  languageType: '',
  overflow: false,
  token: '',
  nickname: ''
};

export const mutations = {
  [types.SHOW_MSG_DLG](state, payload) {
    state.show = payload;
  },

  [types.SHOW_GLOBAL_LOADING](state, payload) {
    state.globalLoading = Object.assign({}, payload);
  },
  SET_DATA(state, payload) {
    state[payload.name] = payload.data
  },
  SET_NICKNAME(state, payload) {
    state.nickname = payload
    db.save('nickname', payload)
  },
  SET_THEME(state, payload) {
    state.theme = payload
    db.save('theme', payload)
  },
  SET_TOKEN(state, payload) {
    state.token = payload
    db.save('token', payload)
  },
  SET_KEEP_ALIVE(state, payload) {
    state.keepAlive = payload
  }
};

export const actions = {
  showGlobalDlg({commit}) {
    commit(types.SHOW_MSG_DLG);
  },

  showGlobalLoading({commit}, obj) {
    commit(types.SHOW_GLOBAL_LOADING, obj);
  },

  addKeepAlive({commit, state}, name){
    let keepAlive = [...state.keepAlive];
    keepAlive.push(name);    
    commit('SET_KEEP_ALIVE', keepAlive)
  },
  delKeepAlive({commit, state}, name){
    let keepAlive = [...state.keepAlive];
    let KAIndex = keepAlive.indexOf(name); 
    if (KAIndex > -1) { 
      keepAlive.splice(KAIndex, 1); 
    } 
    commit('SET_KEEP_ALIVE', keepAlive)
  },
};
