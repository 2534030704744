export default {
    'Integral': 'Ranking',
    'Schedule': 'Fixtures',
    'Player': 'Players',
    'All': 'All',
    'footballer': 'Player',
    'team': 'Team',
    'assist': 'Assist',
    'goal': 'Goal',
    'TopCcorer': 'Goals',
    'assists': 'Assists',
    'Sai': 'MP',
    'Win/draw/lose': 'W/D/L',
    'Win': 'Win',
    'Lose': 'Lose',
    'in/out': 'F/A',
    'integration': 'Pts',
    'confirm': 'Confirm',
    'cancel': 'Cancel',
    'Group Stage': 'Group Stage',
    'mainTitle': '2023 FIBA Basketball World Cup',
    'Year': '/',
    'Month': '/',
    'Day1': '',
    'Day': 'D',
    'Hour': 'H',
    'Minute': 'M',
    'Second': 'Sec',
    'HT': 'HT',
    'QT': 'QT',
    'Corner': 'Corner',
    '90Min': '90Min',
    'Promotion zone': 'Qualification',
    'undetermined': 'Undetermined',
    'thirdGroup':'Group 3rd-place Ranking',
    'firstGroup': 'Groud Stage',
    'secondGroup': '2nd Round',
    'rankGroup': '17th -32nd Classification',
    'rankNum': 'Matches',
    'rank1': 'Final Round',
    'rank2': '5th -8th Classification',
    'rank21': '5th -8th Classif.',
    'rank3': 'Quarter-Finals',
    'rank4': 'Semi-Finals',
    'rank5': 'Final',
    'rank6': '3rd Place Final',
    'rank7': 'Classification',
    'rank8': '5th Place',
    'rank9': '7th Place',
    'Final': 'Final',
    'conntdown': 'C/D',
    'nodata': 'No Data',
    'Finish': 'Ended'
}