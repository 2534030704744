<script src="main.js"></script>
<template>
  <div id="app" :class="colorChange">
    <!-- <keep-alive :include="($route.name == 'memberList' || $route.name == 'betList') ?'memberList':''"> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState("common", ["theme"]),
    colorChange() {
      return this.theme;
    },
  },
}
</script>
<style lang="scss">
.dark{
  background: #000;
}
</style>