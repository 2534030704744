import Vue from 'vue'
import VueI18n from 'vue-i18n'
import db from '@/utils/localStorage'
import enLocale from './en'
import zhLocale from './zh'
import vnLocale from './vn'


import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import enZH from 'vant/es/locale/lang/zh-CN';


Locale.use('en-US', enUS);
Locale.use('en-ZH', enZH);

Vue.use(VueI18n)

const messages = {
  vn: {
    ...vnLocale
  },
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  },
};

export function getLanguage() {
  const defaultLang = 'zh'
  try {
    const chooseLanguage = db.get('LANGUAGE');

    if (typeof chooseLanguage === 'object') {
      // can not get from localStorage, set default value
      // object
      return defaultLang
    } else {
      // string
      return chooseLanguage
    }
  } catch (error) {
    return defaultLang
  }

  // const chooseLanguage = db.get('LANGUAGE');

  // if (chooseLanguage) return chooseLanguage;

  // // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase();
  // const locales = Object.keys(messages);
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale;
  //   }
  // }
  
  // return 'en';
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  silentTranslationWarn: true
});

export default i18n
