import Vue from 'vue';
import Router from 'vue-router';
//import store from '@/store/index';

Vue.use(Router);
const files = require.context('.', false, /\.js$/)
const modules = []

files.keys().forEach(key => {
  if (key === './index.js' || key === './toggel.js') return
  modules.push(...files(key).default)
});
const router = new Router({
  // mode: "history",
  routes: [
    {
      path: '',
      name: 'global',
      component: () => import('@/views/Global'),
      children: [

        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home'),
          children: [
            {
              path: 'schedule',
              name: 'Schedule',
              component: () => import('@/views/Schedule'),
            },
            {
              path: 'player',
              name: 'Player',
              component: () => import('@/views/Player'),
            },
            {
              path: 'integral',
              name: 'Integral',
              component: () => import('@/views/Integral'),
            },
            {
              path: '',
              redirect: 'schedule'
            }
          ]
        },

        ...modules,

      ]
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   const userHasLogin = store.state.user.isLogin;
//   switch (to.name) {
//     case 'login':
//       userHasLogin ?
//         next({ name: 'home' })
//         :
//         next();
//       break;
//     case 'forgetPassword':
//     case 'register':
//       next();
//       break;

//     default: {
//       userHasLogin ?
//         next()
//         :
//         // next()
//         next({ name: 'login' })
//     }
//   }
// });

// router.afterEach(() => {
// });

export default router;
